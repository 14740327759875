import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import data from '../../data/contact';

const ContactIcons = () => (
  <ul className="icons">
    {data.map((s) => (
      <li key={s.label}>
        {s.link ? (
          <>
            <a href={s.link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={s.icon} />
            </a>
            {s.value && <span style={{ marginLeft: '0.5em' }}>{s.value}</span>}
          </>
        ) : (
          <span>
            <FontAwesomeIcon icon={s.icon} />
            {s.value && <span style={{ marginLeft: '0.5em' }}>{s.value}</span>}
          </span>
        )}
      </li>
    ))}
  </ul>
);

export default ContactIcons;
