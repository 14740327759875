import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://github.com/soheil647',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.linkedin.com/in/soheil-shirvani-404521189/',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'mailto:sshir030@ucr.edu',
    label: 'Email',
    icon: faEnvelope,
  },
  {
    link: 'https://scholar.google.com/citations?user=XXXXX', // Replace XXXXX with your Google Scholar ID
    label: 'Google Scholar',
    icon: faGraduationCap, // Use a suitable icon from FontAwesome
  },
  {
    link: '/SoheilShirvani_CV.pdf', // Replace with the actual path to your CV
    label: 'CV',
    icon: faFilePdf,
    value: 'CV',
  },
  {
    link: 'https://www.google.com/maps/place/Irvine,+CA/@33.6865965,-117.8559594,12z/data=!3m1!4b1!4m6!3m5!1s0x80dcdd0e689140e3:0xa77ab575604a9a39!8m2!3d33.6845673!4d-117.8265049!16zL20vMGQ3azF6?entry=ttu&g_ep=EgoyMDI1MDEyMS4wIKXMDSoASAFQAw%3D%3D',
    label: 'Location',
    icon: faMapMarkerAlt,
    value: 'Irvine, California',
  },
  {
    link: 'https://www.ucr.edu/',
    label: 'University',
    icon: faUniversity,
    value: 'University of California, Riverside',
  },
];

export default data;
